import Link from "next/link"
import { Fragment } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { LegendInput } from "@/components/common/Form"
import Card from "@/components/common/Card"
import { SubmitButton } from "@/components/common/Button"
import { MobileHero, DesktopHero } from "@/components/Layout/Hero"
import ErrorContainer, { ErrorHint } from "@/components/common/ErrorHint"
import { emailSchema } from "@/services/utils/validationSchema"
import { useMultilang } from "@/hooks/useMultilang"

const passwordSchema = (t) =>
  yup.object({
    password: yup
      .string()
      .label("Password")
      .required(t("error-msg.password-required")),
  })

const validationSchema = (t) => emailSchema(t).concat(passwordSchema(t))

const LoginForm = function ({ onSubmit, children, data }) {
  const { t, multilang } = useMultilang()
  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(validationSchema(t)),
  })
  const keys = ["imageUrl", "textContent"]
  const [image, text] = keys.map((key) => multilang(data, key))

  return (
    <Fragment>
      <MobileHero imageSrc={image}>
        <p className="px-8 pt-10 pb-20">{text}</p>
      </MobileHero>
      <Card className="relative max-w-[358px] text-sm mx-auto bg-white -mt-20  mb-8 tablet:mt-0 tablet:flex tablet:max-w-[680px] tablet:h-[630px] tablet:py-0">
        <DesktopHero imageSrc={image}>
          <p className="pt-9 pl-8">{text}</p>
        </DesktopHero>
        <form className="flex flex-col py-5 px-8 w-full">
          <div className="flex justify-evenly border-b border-gray-200 mb-8 p-2">
            <p className="capitalize font-bold after:content-[''] after:absolute after:w-8 after:h-[3px] after:left-1/2 after:-bottom-2.5 after:-translate-x-1/2 after:bg-black-900 relative">
              {t("auth.tab.login")}
            </p>
            <Link href="/signup">
              <a className="capitalize"> {t("auth.tab.signup")} </a>
            </Link>
          </div>
          <ErrorContainer>
            <LegendInput
              name="email"
              displayName={t("label.email-address")}
              placeholder={t("label.email-address")}
              register={register}
              dangerStyle="border-red-100 text-red-100"
              errors={formState.errors}
            />
            {formState.errors.email && (
              <ErrorHint>{formState.errors.email.message}</ErrorHint>
            )}
          </ErrorContainer>
          <ErrorContainer>
            <LegendInput
              type="password"
              name="password"
              displayName={t("label.password")}
              placeholder={t("label.password")}
              register={register}
              dangerStyle="border-red-100 text-red-100"
              errors={formState.errors}
            />
            {formState.errors.password && (
              <ErrorHint>{formState.errors.password.message}</ErrorHint>
            )}
          </ErrorContainer>
          <div className="mb-5">
            <Link href="/recovery">
              <a className="underline text-gray-500 text-left text-xxs">
                {t("auth.link.forget-pw")}
              </a>
            </Link>
          </div>
          <SubmitButton
            submit={handleSubmit((data) =>
              onSubmit(data).catch(() => {
                setError("password", {
                  message: t("error-msg.incorrect-account"),
                  type: "api error",
                })
              })
            )}
          >
            {t("btn.signin")}
          </SubmitButton>
          {children ? children : null}
        </form>
      </Card>
    </Fragment>
  )
}

export default LoginForm

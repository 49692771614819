import { classNames } from "@/services/utils"

const Card = function ({ children, className, py = "py-5", ...rest }) {
  const classes = classNames(`shadow ${py}`, className)
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

export default Card

import * as yup from "yup"
import { passwordRegex } from "../regex"

export const emailSchema = (t) => {
  return yup.object({
    email: yup
      .string()
      .required(t("error-msg.email-format"))
      .email(t("error-msg.email-format")),
  })
}

export const passwordSchema = (t) => {
  return yup.object({
    password: yup
      .string()
      .label("Password")
      .required(t("error-msg.password-required"))
      .min(8, " ")
      .matches(passwordRegex, " "),
  })
}

export const regionEmailSchema = (t) => {
  return yup
    .object({
      region: yup.string().required(),
    })
    .concat(emailSchema(t))
}

export const passwordConfirmSchema = (t) => {
  return yup
    .object({
      password2: yup
        .string()
        .required(" ")
        .min(8, " ")
        .matches(passwordRegex, " ")
        .oneOf([yup.ref("password")], t("error-msg.password-should-match")),
    })
    .concat(passwordSchema(t))
}

export const baseValidationSchema = (t) => {
  return regionEmailSchema(t)
    .concat(passwordConfirmSchema(t))
    .shape({
      privacy: yup.bool().oneOf([true]),
    })
}

export const EmptyNumber = (
  typeErrorMessage = "Please enter a valid number"
) => {
  return yup
    .number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value
      if (!originalValue || !originalValue.trim()) {
        return null
      }
      // we return the invalid original value
      return originalValue
    })
    .nullable(true)
    .typeError(typeErrorMessage)
}

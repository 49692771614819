export const MobileHero = function ({ imageSrc, children }) {
  return (
    <div className=" w-full top-0 left-0 tablet:hidden ">
      <div
        className="relative min-h-[265px] w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${imageSrc})` }}
      >
        <div className=" text-white z-10 text-xl font-bold leading-7">
          {children}
        </div>
      </div>
    </div>
  )
}

export const DesktopHero = function ({ imageSrc, children }) {
  return (
    <div
      className="hidden tablet:block relative w-1/2 shrink-0 bg-cover bg-center"
      style={{ backgroundImage: `url(${imageSrc})` }}
    >
      <div className="absolute text-white z-10 text-xl font-bold w-full">
        {children}
      </div>
    </div>
  )
}

import { useRouter } from "next/router"
import LoginForm from "@/components/Login"
import Separator from "@/components/common/Separator"
import { LayoutWithoutFooter } from "@/components/Layout"
import getServerProps, { redirectLocale } from "@/services/utils/getServerProps"
import { useMultilang } from "@/hooks/useMultilang"
import { getLoginContent } from "@/services/contents"
import Meta from "@/components/Meta"
import Debug from "debug"
import OAuth from "@/components/SocialMedia/Oauth"
import { doUserLogin } from "@/services/utils/authentication"
import Head from "next/head"

const debug = Debug("web:page:login")

export const getServerSideProps = async (props) => {
  const redirect = redirectLocale(props)
  if (redirect) return redirect
  const homeData = await getLoginContent()
  return {
    props: {
      ...(await getServerProps(props)),
      homeData,
    },
  }
}

function LoginPage(props) {
  const router = useRouter()
  const { t } = useMultilang()
  const loginUser = doUserLogin({ router, debug })

  return (
    <LayoutWithoutFooter>
      <Meta path="/login" />
      <Head>
        <meta
          key="og:image"
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_DOMAIN}/image/why-kronos-1@2x.jpg`}
        />
        :
      </Head>
      <LoginForm onSubmit={loginUser} data={props.homeData}>
        <Separator> {t("auth.or")} </Separator>
        <h3 className="mb-2"> {t("auth.connect-with")} </h3>
        <div className="flex">
          <OAuth debug={debug} wechatAction="login" />
        </div>
      </LoginForm>
    </LayoutWithoutFooter>
  )
}

export default LoginPage
